import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import UploadAvatar from "../../components/web/image/onboarding/UploadAvatar";
import { useSelector } from "react-redux";
import { updateProfile } from "../../actions/user";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Main() {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("grid");
  const [filter, setFilter] = useState(0);
  const [create, setCreate] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [form, setForm] = useState(user);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  function handleClick() {
    dispatch(updateProfile(form));
  }

  return (
    <div>
      <Grid container direction="row">
        <Grid item md={6}>
          <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
            <b>Profile</b>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Edit your profile
          </Typography>

          <div style={{}}>
            <UploadAvatar
              uri={form.avatar}
              onComplete={(value) =>
                setForm((form) => ({ ...form, avatar: value }))
              }
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <TextField
              name="name"
              label="Full Name"
              onChange={handleChange}
              defaultValue={form.name}
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="phone"
              label="Phone Number"
              size="small"
              onChange={handleChange}
              defaultValue={form.phone}
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="email"
              onChange={handleChange}
              defaultValue={form.email}
              label="Email"
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="company_name"
              onChange={handleChange}
              defaultValue={form.company_name}
              label="Company Name"
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="company_reg"
              onChange={handleChange}
              defaultValue={form.company_reg}
              label="Company Reg Number"
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="company_address"
              defaultValue={form.company_address}
              onChange={handleChange}
              label="Company Address"
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <TextField
              name="about"
              onChange={handleChange}
              defaultValue={form.about}
              label="About"
              multiline="true"
              rows="4"
              size="small"
              fullWidth
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={4}>
                <TextField
                  name="facebook"
                  onChange={handleChange}
                  defaultValue={form.facebook}
                  label="Facebook"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  name="twitter"
                  onChange={handleChange}
                  defaultValue={form.twitter}
                  label="Twitter"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  name="instagram"
                  onChange={handleChange}
                  defaultValue={form.instagram}
                  label="Instagram"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: 20 }}>
            <Button name="continue" onClick={handleClick} variant="contained">
              <Typography fontFamily={"SpaceBold"} variant="body1">
                Save and Continue
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
