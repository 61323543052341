import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { requirePropFactory } from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import LivingIcon from "@mui/icons-material/Living";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import GarageIcon from "@mui/icons-material/Garage";

import getSymbolFromCurrency from "currency-symbol-map";

import AssetCardImage from "../../components/image/onboarding/AssetCardMedia";
import RealtorAvatar from "../../components/image/onboarding/RealtorAvatar";
import { onBoard } from "../../actions/onboard";

export default function Main(props) {
  const dispatch = useDispatch();
  const listing = useSelector((state) => state.onboard.listing);
  const realtor = useSelector((state) => state.onboard.realtor);

  function handleClick() {
    const body = { realtor: realtor, listing: listing };
    dispatch(onBoard(body, props));
  }
  return (
    <div style={{ padding: 20, height: "100%" }}>
      <Typography variant="h6" fontFamily={"InterBold"}>
        Preview
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Preview your listing
      </Typography>

      <div style={{ marginTop: 20 }} />

      <Card
        elevation={0}
        sx={{
          maxWidth: 345,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 5,
          borderColor: "#E5E4E2",
        }}
      >
        <AssetCardImage uri={listing.images.split(",")[0]} />
        <CardContent>
          <Typography variant="h6" fontFamily={"InterBold"}>
            {listing.title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {listing.address}
          </Typography>
          <Typography variant="h6" fontFamily={"SpaceBold"}>
            {getSymbolFromCurrency("NGN")}
            {listing.type == "sale"
              ? listing.price
              : listing.rent + "/" + listing.rent_period}
          </Typography>

          <div style={{ marginTop: 20 }}>
            <Chip
              color="success"
              size="small"
              sx={{ borderRadius: 0 }}
              label={
                <Typography color="inherit" variant="body1">
                  Features
                </Typography>
              }
            />

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <BedIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.bedrooms} Bedrooms{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <LivingIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.livingrooms} Living Rooms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <ShowerIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.bathrooms} Bathrooms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <KitchenIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.kitchens} Kitchens
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <SquareFootIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.total_area} sqft.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <GarageIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.garages} Garages
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item md={2}>
              <RealtorAvatar uri={realtor.avatar} />
            </Grid>
            <Grid item md={10}>
              <Typography variant="h6" fontFamily={"InterBold"}>
                <b>{realtor.name}</b>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {realtor.company_name}
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginTop: 10 }}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <LocalPhoneIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {realtor.phone}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1}>
              <Grid item>
                <MailOutlineIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {realtor.email}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>

      <div style={{ marginTop: 20 }} />

      <div style={{ display: "flex" }}>
        <Button onClick={handleClick} fullWidth variant="contained">
          <Typography variant="body1" fontFamily={"SpaceBold"}>
            Finish
          </Typography>
        </Button>
      </div>
    </div>
  );
}
