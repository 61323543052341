import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

import { fetchRequest } from "../../../actions/requests";

export default function Main(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleClick() {
    dispatch(fetchRequest(props.data));
    history.push("/requests/request");
  }

  return (
    <ListItem
      button
      onClick={handleClick}
      divider
      disablePadding
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item>
          <div style={{ direction: "row", display: "flex" }}>
            <Typography fontFamily="SpaceBold" variant="body1">
              {props.data.created.substr(0, 10)} |{" "}
              {props.data.created.substr(11, 5)}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Chip
        size="small"
        style={{ borderRadius: 0 }}
        color="warning"
        label={<Typography variant="body2">{props.data.category}</Typography>}
      />
      <div style={{ marginTop: 10 }} />
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" noWrap>
            {props.data.description}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}
