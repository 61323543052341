import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { uri } from "../../../constants/settings";

export default function Main() {
  const listing = useSelector((state) => state.listing.listing);
  const host = uri.mode === "dev" ? uri.dev : uri.live;
  return (
    <ImageList sx={{ width: "100%", height: 450 }} cols={2} rowHeight={164}>
      {listing.images ? (
        listing.images.split(",").map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${
                host + "/gallery/listing/image/" + item
              }?w=500&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${
                host + "/gallery/listing/image/" + item
              }w=500&h=164&fit=crop&auto=format`}
              //srcSet={`${item.img}?w=500&h=164&fit=crop&auto=format&dpr=2 2x`}
              //src={`${item.img}?w=500&h=164&fit=crop&auto=format`}
              alt={item.title}
              style={{ height: 200 }}
              loading="lazy"
            />
          </ImageListItem>
        ))
      ) : (
        <Typography variant="body1" align="center">
          No images uploaded{" "}
        </Typography>
      )}
    </ImageList>
  );
}
