import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CardMedia from "@mui/material/CardMedia";
import ButtonBase from "@mui/material/ButtonBase";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";

import { wait, notify, unexpectedError } from "../../../actions/index";

import { uri } from "../../../constants/settings";
import { CardActionArea } from "@mui/material";

const base64 = require("base-64");

const styles = {
  input: {
    display: "none",
  },
};

export default function Main(props) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ preview: "" });
  const user = "";
  const pass = "";
  const credentials = user + ":" + pass;

  useEffect(() => {
    fetchPhoto();
  }, []);

  async function fetchPhoto() {
    const user = "mojeed.oyedeji@gmail.com";
    const pass = "omopeyemi";
    const credentials = user + ":" + pass;
    var url = "";
    if (uri.mode == "test") {
      url = uri.test + "/gallery/listing/image/" + props.uri;
    } else if (uri.mode == "live") {
      url = uri.live + "/gallery/listing/image/" + props.uri;
    } else {
      url = uri.dev + "/gallery/listing/image/" + props.uri;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
        Authorization: "Basic " + base64.encode(credentials),
      },
      body: JSON.stringify({
        uri: props.uri,
      }),
    })
      .then((response) => response.blob())
      .then((res) => {
        if (res.size > 100) {
          setForm({
            photo: res,
            preview: URL.createObjectURL(res),
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  return (
    <CardMedia
      component="img"
      alt="green iguana"
      height="140"
      image={
        form.preview ? form.preview : require("../../../images/property/01.jpg")
      }
    />
  );
}
