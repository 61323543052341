import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MouseIcon from "@mui/icons-material/Mouse";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";

import AddLink from "../../components/mobile/links/AddLink";
import EditLink from "../../components/mobile/links/EditLink";

import { getLinks, fetchLink, deleteLink } from "../../actions/artifacts";
import ConfirmationModal from "../../components/modals/Confirmation";
export default function Main() {
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const user = useSelector((state) => state.user.user);
  const linkings = useSelector((state) => state.artifacts.linkings);
  const [data, setData] = useState(linkings ? linkings : []);
  const [modal, setModal] = useState({ add: false, edit: false });

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getLinks(body));
  }, []);

  useEffect(() => {
    setData(linkings ? linkings : []);
  }, [linkings]);

  return (
    <div>
      <AddLink
        open={modal.add}
        close={() => setModal((modal) => ({ ...modal, add: false }))}
      />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={11}>
          <Grid container direction="row">
            <Grid item md={6}>
              <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
                Links
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Manage your links
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }} />
          <Grid container direction="row">
            <Grid item md={2}>
              <Button
                onClick={() => setModal((modal) => ({ ...modal, add: true }))}
                variant="outlined"
                startIcon={<AddIcon />}
              >
                <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
                  Add Link
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <div style={{ marginTop: 40 }} />
          {data.length > 0 ? (
            data.map((item) => <LinkItem data={item} />)
          ) : (
            <Typography color="textSecondary" align="center" variant="body1">
              {"  No links yet "}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function LinkItem(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menu = Boolean(anchorEl);
  const [modal, setModal] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen() {
    window.open(props.data.url);
  }

  function handleEdit() {
    dispatch(fetchLink(props.data));
    setModal(true);
  }

  function handleDelete() {
    const body = { id: props.data.id, owner: props.data.owner };
    dispatch(deleteLink(body));
    setModal(false);
  }

  function handleConfirm() {
    setConfirm(true);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <EditLink open={modal} close={() => setModal(false)} />
      <ConfirmationModal
        open={confirm}
        confirm={handleDelete}
        close={() => setConfirm(false)}
      />
      <Grid
        container
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Grid item xs={10}>
          <Typography variant="body1" fontFamily="InterBold" align="justify">
            {props.data.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="justify">
            {props.data.description}
          </Typography>
        </Grid>

        <Grid item>
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            {" "}
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpen}>
              <OpenInNewIcon color="success" />{" "}
              <Typography variant="body2"> Open in New Tab</Typography>
            </MenuItem>
            <MenuItem onClick={handleEdit}>
              <EditIcon color="warning" />{" "}
              <Typography variant="body2">Edit</Typography>
            </MenuItem>
            <MenuItem onClick={handleConfirm}>
              <DeleteIcon color="error" />{" "}
              <Typography variant="body2">Delete</Typography>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <div style={{ marginTop: 5 }} />
      <Divider />
    </div>
  );
}
