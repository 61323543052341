import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Listings from "../../components/mobile/dashboard/Listings";
import Summary from "../../components/mobile/dashboard/Summary";
import PieCharts from "../../components/mobile/dashboard/PieCharts";
import ListingValue from "../../components/mobile/dashboard/ListingValue";

export default function Main() {
  const user = useSelector((state) => state.user.user);

  return (
    <div>
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="h4" fontFamily={"InterBold"}>
            {" "}
            Hi, {user.name}
          </Typography>

          <div style={{ marginTop: 30 }} />
          <Summary />

          <div style={{ marginTop: 40 }} />
          <PieCharts />

          <div style={{ marginTop: 40 }} />

          <ListingValue />
          <div style={{ marginTop: 20 }} />
        </Grid>
      </Grid>
    </div>
  );
}
