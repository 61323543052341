import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Default from "./Default";
import Request from "./Request";
import FooterMenu from "../../components/mobile/page/FooterMenu";

import { getRequests } from "../../actions/requests";

export default function BottomAppBar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [screen, setScreen] = useState("default");
  let { page } = useParams();

  useEffect(() => {
    setScreen(page == null ? "default" : page);
  }, [page]);

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getRequests(body));
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <div style={{ padding: 0, marginBottom: 50, marginTop: 50 }}>
        {getScreen(screen)}
      </div>

      <div style={{ marginTop: 150 }} />
      <FooterMenu />
    </React.Fragment>
  );
}

function getScreen(screen) {
  switch (screen) {
    case "default":
      return <Default />;
    case "request":
      return <Request />;
  }
}
