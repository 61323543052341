import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

export default function Main() {
  const listing = useSelector((state) => state.listing.listing);

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item md={12}>
        <div style={{ marginTop: 10 }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontFamily: "UberBold" }}
          >
            DESCRIPTION
          </Typography>
          <Typography variant="body1">{listing.description}</Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontFamily: "UberBold" }}
          >
            ADDRESS
          </Typography>
          <Typography variant="body1">{listing.address}</Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Grid container direction="row">
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                PRICE
              </Typography>
              <Typography variant="body1">{listing.price}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                BEDROOMS
              </Typography>
              <Typography variant="body1">{listing.bedrooms}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                LIVING ROOMS
              </Typography>
              <Typography variant="body1">{listing.livingrooms}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                GARAGES
              </Typography>
              <Typography variant="body1">{listing.garages}</Typography>
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: 10 }}>
          <Grid container direction="row">
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                KITCHENS
              </Typography>
              <Typography variant="body1">{listing.kitchens}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontFamily: "UberBold" }}
              >
                TOTAL AREA (SQFT)
              </Typography>
              <Typography variant="body1">{listing.total_area}</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={4}></Grid>
    </Grid>
  );
}
