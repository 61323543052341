import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import "react-alice-carousel/lib/alice-carousel.css";
import ReactCodeInput from "react-code-input";

import { page } from "../../styles/main";

import { findUser } from "../../actions/user";

const codeInputProps = {
  inputStyle: {
    fontFamily: "monospace",
    margin: "4px",
    MozAppearance: "textfield",
    width: "40px",
    height: "30px",
    borderRadius: "3px",
    fontSize: "20px",
    textAlign: "center",
    color: "black",
    border: "1px solid black",
  },
  inputStyleInvalid: {
    fontFamily: "monospace",
    margin: "4px",
    MozAppearance: "textfield",
    width: "60px",
    height: "40px",
    borderRadius: "3px",
    fontSize: "20px",
    textAlign: "center",
    color: "red",
    border: "1px solid red",
  },
};

export default function Main() {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [page, setPage] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function handleSwitch() {}

  function moveNext() {
    setSelectedIndex(selectedIndex + 1);
  }

  return (
    <div style={page.root}>
      <Grid
        direction="row"
        style={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item md={10} xs={12} sm={12} style={{ height: "80%" }}>
          <Grid
            container
            direction="row"
            style={{ height: "100%" }}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={11} sm={8}>
              <Grid
                style={{ height: "100%" }}
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid item>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <img
                      style={{ width: 120, height: 30 }}
                      src={require("../../images/logo/1x/Asset 1.png")}
                    />
                  </div>
                </Grid>
                <Grid item>
                  {page == 0 ? (
                    <LoginForm switch={() => setPage(1)} />
                  ) : (
                    <AuthForm switch={() => setPage(0)} />
                  )}
                </Grid>
                <Grid item>
                  <Typography>
                    Don't have an account?{" "}
                    <a name="register" href="/onboard">
                      Get Started
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ marginTop: 0 }}></div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function LoginForm(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  function handleClick() {
    const body = { user: email };
    dispatch(findUser(body, props));
  }

  function handleChange() {}
  return (
    <div>
      <Typography variant="h4" color="primary" fontFamily={"InterBold"}>
        Login
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Provide your email address.
      </Typography>
      <div style={{ marginTop: 20 }}>
        <TextField
          onChange={(event) => setEmail(event.target.value)}
          name="phone"
          style={{ width: "100%" }}
          variant="standard"
          size="small"
          id="credential"
          label="Email Address"
        />
      </div>

      <div style={{ marginTop: 40 }} />
      <Button
        onClick={handleClick}
        style={{ width: "100%" }}
        variant="contained"
      >
        <Typography variant="body1" fontFamily="SpaceBold">
          {" "}
          Continue{" "}
        </Typography>
      </Button>
    </div>
  );
}

function AuthForm(props) {
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");

  function handlePinChange(pinCode) {
    setPinCode(pinCode);
  }

  function handleClick() {
    if (pinCode == user.auth) {
      history.push("/dashboard");
    }
  }

  function handleChange() {}
  return (
    <div>
      <Typography variant="h4" color="primary" fontFamily={"InterBold"}>
        Authentication
      </Typography>

      <Box>
        <Typography color="textSecondary">
          Please provide the authentication code sent to your email address
        </Typography>
      </Box>
      <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
        <ReactCodeInput
          id="pinCode"
          type="number"
          isValid={isPinCodeValid}
          fields={6}
          onChange={handlePinChange}
          value={pinCode}
          {...codeInputProps}
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Button
          onClick={handleClick}
          style={{ width: "100%" }}
          variant="contained"
        >
          <Typography variant="body1" fontFamily="SpaceBold">
            {" "}
            <b>Continue </b>{" "}
          </Typography>
        </Button>
      </div>
    </div>
  );
}
