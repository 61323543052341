import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Default from "./Default";
import FooterMenu from "../../components/mobile/page/FooterMenu";

import { getLinks } from "../../actions/artifacts";

export default function BottomAppBar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("default");

  let { page } = useParams();

  useEffect(() => {
    console.log(page);
    setScreen(page == null ? "default" : page);
  }, [page]);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <CssBaseline />

      <div style={{ padding: 0, marginBottom: 50, marginTop: 50 }}>
        {getScreen(screen)}
      </div>

      <div style={{ marginTop: 150 }} />
      <FooterMenu />
    </React.Fragment>
  );
}

function getScreen(screen) {
  switch (screen) {
    case "default":
      return <Default />;
  }
}
