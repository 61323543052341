import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import EditForm from "../../components/mobile/listing/EditForm";

import { updateListing } from "../../actions/listings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Main() {
  const dispatch = useDispatch();
  const listing = useSelector((state) => state.listing.listing);
  const [form, setForm] = useState(listing);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleClick() {}

  function handleSave() {
    dispatch(updateListing());
  }

  function handleSavePublish() {}

  return (
    <div>
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={11}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" fontFamily="InterBold">
                Edit Listings
              </Typography>
            </Grid>
          </Grid>

          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/listings">
                All Listings
              </Link>
              <Typography color="text.primary">Edit Listing</Typography>
            </Breadcrumbs>
          </div>

          <div style={{ marginTop: 10 }} />
          <EditForm />
        </Grid>
      </Grid>
    </div>
  );
}
