import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import Summary from "../../components/web/dashboard/Summary";
import PieCharts from "../../components/web/dashboard/PieCharts";
import ListingValue from "../../components/web/dashboard/ListingValue";
import ListingChart from "../../components/web/dashboard/ListingChart";

import { averageRating, ratingDistribution } from "../../functions/chart";
import { getReviews } from "../../actions/reviews";

export default function Main() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const reviews = useSelector((state) => state.review.reviews);
  const pieData = ratingDistribution(reviews);
  useEffect(() => {
    const body = { id: user.id };
    dispatch(getReviews(body));
  }, []);

  function handleClick() {}
  return (
    <div>
      <div>
        <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
          Hello, {user.name}
        </Typography>
        <Link href={"https://my.rentage.app/" + user.url}>
          <Typography variant="body1" color="textSecondary">
            {"https://my.rentage.app/" + user.url}
          </Typography>
        </Link>
      </div>

      <div style={{ marginTop: 20 }} />

      <Summary />

      <div style={{ marginTop: 20 }}>
        <PieCharts />

        <Grid container direction="row">
          <Grid item md={6}>
            <ListingValue />
          </Grid>
          <Grid item md={6}>
            <ListingChart />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Header() {
  return <div></div>;
}
