import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import AssetCardMedia from "../image/listing/AssetCardMedia";

export default function Main() {
  const listing = useSelector((state) => state.listing.listing);

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <Typography variant="body2" color="textSecondary">
          DESCRIPTION
        </Typography>
        <Typography variant="body1">{listing.description}</Typography>
      </div>

      <div style={{ marginTop: 10 }}>
        <Typography variant="body2" color="textSecondary">
          ADDRESS
        </Typography>
        <Typography variant="body1">{listing.address}</Typography>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              PRICE
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.price}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              BEDROOMS
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.bedrooms}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              LIVING ROOMS
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.livingrooms}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              GARAGES
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.garages}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              KITCHENS
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.kitchens}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              TOTAL AREA (SQFT)
            </Typography>
            <Typography variant="body1" fontFamily="SpaceBold">
              {listing.total_area}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
