import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Default from "./Dashboard";
import FooterMenu from "../../components/mobile/page/FooterMenu";
import { getListings } from "../../actions/listings";
import { getReviews } from "../../actions/reviews";

export default function BottomAppBar() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const body = { realtor: user.id };
    dispatch(getListings(body));
    dispatch(getReviews({ id: user.id }));
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />

      <div style={{ padding: 0, marginBottom: 50, marginTop: 50 }}>
        <Default />
      </div>

      <div style={{ marginTop: 150 }} />
      <FooterMenu />
    </React.Fragment>
  );
}
