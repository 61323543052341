import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import StepLabel from "@mui/material/StepLabel";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Notify from "../../components/alerts/Main";

import { fetchListing } from "../../actions/onboard";

import * as validator from "../../validators/listings";

import AssetImage from "../../components/image/onboarding/AssetImage";
import { wait, notify, unexpectedError } from "../../actions/index";
import { uri } from "../../constants/settings";
const base64 = require("base-64");

const styles = {
  input: {
    display: "none",
  },
};

const initialState = {
  title: "",
  location: "",
  description: "",
  bedrooms: "",
  bathrooms: "",
  kitchens: "",
  total_area: "",
  property_type: "",
};

const steps = ["About", "Features", "Images"];

export default function Main(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const listing = useSelector((state) => state.onboard.listing);
  const realtor = useSelector((state) => state.onboard.realtor);

  const [form, setForm] = useState(listing || {});

  function handleClick() {
    var status = "success",
      message = "ok";
    if (status == "success") {
      const body = { realtor: realtor, listing: form };
      dispatch(fetchListing(form));
      props.complete();
    } else {
      dispatch(notify({ show: true, message: message, status: status }));
    }
  }

  const handleNext = () => {
    if (activeStep < 2) {
      const [status, message] = validator.addListing(form, activeStep);
      if (status == "success") {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        dispatch(notify({ status: status, message: message }));
      }
    } else if (activeStep == steps.length - 1) {
      const body = { realtor: realtor, listing: form };
      dispatch(fetchListing(form));
      props.complete();
    }
  };

  function handleImageChange(filename) {
    if (form.images == "" || form.images == null) {
      setForm((form) => ({ ...form, images: filename }));
    } else {
      let newFileName = form.images + "," + filename;
      setForm((form) => ({ ...form, images: newFileName }));
    }
  }

  function handleRemoveImage(value) {
    let images = form.images.split(",");
    images.pop(value);
    let new_images = images.join(",");
    setForm((form) => ({ ...form, images: new_images }));
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <div style={{ padding: 20, height: "100%" }}>
      <Typography variant="h6" style={{ fontFamily: "InterBold" }}>
        Add Listing
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Please add a listing
      </Typography>

      <div style={{ marginTop: 20 }} />
      <Notify />

      <Stepper activeStep={activeStep} style={{ width: "100%" }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography variant="body1">{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <>
          <div style={{ marginTop: 50 }} />
          {activeStep == 0 && (
            <AboutForm form={form} handleChange={handleChange} />
          )}
          {activeStep == 1 && (
            <FeatureForm form={form} handleChange={handleChange} />
          )}
          {activeStep == 2 && (
            <ImageForm
              form={form}
              handleImageChange={handleImageChange}
              removeImage={(value) => handleRemoveImage(value)}
            />
          )}

          <div style={{ marginTop: 50 }} />
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            <Typography variant="body1" fontFamily={"SpaceBold"}>
              Back
            </Typography>
          </Button>

          <Button onClick={handleNext}>
            <Typography variant="body1" fontFamily="SpaceBold">
              {" "}
              {activeStep === steps.length - 1 ? "Save & Continue" : "Next"}
            </Typography>
          </Button>
        </>
      )}
    </div>
  );
}

function AboutForm(props) {
  const [form, setForm] = useState(props.form);

  function handleChange(event) {
    props.handleChange(event);
  }

  useEffect(() => {
    console.log(props.form);
  }, [props.form]);
  return (
    <>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          <Typography variant="body2" style={{ fontSize: 10 }}>
            Property Type
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="type"
          value={props.form.type}
          onChange={handleChange}
        >
          <FormControlLabel value="sale" control={<Radio />} label="Sale" />
          <FormControlLabel value="rental" control={<Radio />} label="Rental" />
        </RadioGroup>
      </FormControl>
      <div style={{ marginTop: 10 }}>
        <TextField
          name="title"
          required
          variant="filled"
          defaultValue={form.title}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Title"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="address"
          required
          variant="filled"
          defaultValue={form.address}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Address"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="description"
          required
          variant="filled"
          defaultValue={form.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          size="small"
          label="Description"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="State"
              name="state"
              required
              select
              variant="filled"
              onChange={handleChange}
              defaultValue={form.state}
              size="small"
              fullWidth
            >
              <MenuItem value="lagos"> Lagos </MenuItem>
              <MenuItem value="abuja"> Abuja </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Region/Location"
              name="location"
              required
              variant="filled"
              onChange={handleChange}
              defaultValue={form.location}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          {props.form.type != "rental" && (
            <Grid item xs={4}>
              <TextField
                label="Price"
                required
                type="number"
                name="price"
                variant="filled"
                onChange={handleChange}
                defaultValue={form.price}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {props.form.type == "rental" && (
            <>
              <Grid item xs={4}>
                <TextField
                  label="Rent"
                  required
                  type="number"
                  name="rent"
                  variant="filled"
                  onChange={handleChange}
                  defaultValue={form.rent}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Period"
                  name="rent_period"
                  required
                  select
                  variant="filled"
                  onChange={handleChange}
                  defaultValue={form.rent_period}
                  size="small"
                  fullWidth
                >
                  <MenuItem value="per-month"> Per Month </MenuItem>
                  <MenuItem value="per-annum"> Per Annum </MenuItem>
                </TextField>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
}

function FeatureForm(props) {
  const [form, setForm] = useState(props.form);

  function handleChange(event) {
    props.handleChange(event);
  }
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Living Rooms"
              name="livingrooms"
              size="small"
              required
              type="number"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.livingrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bedrooms"
              required
              name="bedrooms"
              type="number"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bedrooms}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Bathrooms"
              name="bathrooms"
              required
              size="small"
              type="number"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bathrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Kitchens"
              required
              name="kitchens"
              type="number"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.kitchens}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Garages"
              name="garages"
              required
              type="number"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.garages}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total Area (sqft)"
              name="total_area"
              required
              size="small"
              type="number"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.total_area}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function ImageForm(props) {
  const [form, setForm] = useState(props.form);
  const loading = useSelector((state) => state.app.loading);
  const dispatch = useDispatch();
  const user = "";
  const pass = "";
  const host = uri.mode === "dev" ? uri.dev : uri.live;
  const credentials = user + ":" + pass;

  useEffect(() => {
    setForm(props.form);
  }, [props.form]);

  async function onChange(e) {
    e.preventDefault();
    dispatch(wait(true));
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("photo", e.target.files[0]);

      var url = "";

      if (uri.mode == "test") {
        url = uri.test + "/gallery/listing/upload/";
      } else if (uri.mode == "live") {
        url = uri.live + "/gallery/listing/upload/";
      } else {
        url = uri.dev + "/gallery/listing/upload/";
      }

      setForm({
        photo: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Basic " + base64.encode(credentials),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          dispatch(notify({ message: "Image uploaded", status: "success" }));
          props.handleImageChange(res.response.image);
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            notify({ message: "Unexpected error occured", status: "error" })
          );
        });
    }
    dispatch(wait(false));
  }

  function handleChange(event) {
    props.handleChange(event);
  }
  return (
    <>
      <Alert icon={false} severity="info">
        <Typography variant="body2">
          Please upload at least two images
        </Typography>
      </Alert>

      <div style={{ marginTop: 10 }} />

      {loading == true && <LinearProgress color="primary" />}

      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <IconButton component="label">
            <input
              onChange={(event) => onChange(event)}
              accept="image/*"
              id={props.name}
              style={styles.input}
              multiple
              type="file"
            />
            <AddPhotoAlternateIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>

      <ImageList sx={{ width: "100%", height: 350 }} cols={2} rowHeight={164}>
        {form.images ? (
          form.images.split(",").map((item) => (
            <ImageListItem key={item.img} sx={{ height: 100 }}>
              <img
                srcSet={`${host + "/gallery/listing/image/" + item}`}
                src={`${host + "/gallery/listing/image/" + item}`}
                alt={item.title}
                style={{ height: 200 }}
                loading="lazy"
              />
              <ImageListItemBar
                title={item}
                subtitle={item}
                position="bottom"
                actionIcon={
                  <IconButton
                    onClick={() => props.removeImage(item)}
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  >
                    <ClearIcon color="inherit" />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))
        ) : (
          <Typography variant="body1"></Typography>
        )}
      </ImageList>
    </>
  );
}
