import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import { FETCH_LISTING, FETCH_LISTINGS } from "../constants/listings";

import * as validator from "../validators/listing";

export function getFeatured() {
  const url = "/realtor/listings/featured";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, {});
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getListings(body) {
  const url = "/realtor/listings/all";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getFeaturedListings(body) {
  const url = "/realtor/listings/featured";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function createListing(body, props) {
  const url = "/realtor/listings/create";
  return async (dispatch) => {
    dispatch(wait(true));
    console.log(body);
    var [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
        dispatch(
          notify({ message: "Listing created successfully", status: "success" })
        );
        props.handleClose();
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function updateListing(body, props) {
  const url = "/realtor/listings/update";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
        dispatch(
          notify({
            message: "Listing updated successfully",
            status: "success",
          })
        );
        props.handleClose();
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }

    dispatch(wait(false));
  };
}

export function deleteListing(body, history) {
  const url = "/realtor/listings/delete";
  return async (dispatch) => {
    dispatch(wait(true));
    var [status, message] = validator.create(body);
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if (status == "ok") {
        if (res.response != null) {
          dispatch(fetchListings(res.response.listings));
          dispatch(
            notify({
              message: "Listing updated successfully",
              status: "success",
            })
          );
          history.push("/listings");
        } else {
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: message, status: "error", show: true }));
    }
    dispatch(wait(false));
  };
}

export function fetchListing(payload) {
  return { type: FETCH_LISTING, payload };
}

export function fetchListings(payload) {
  return { type: FETCH_LISTINGS, payload };
}
