import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import getSymbolFromCurrency from "currency-symbol-map";
import ListingAvatar from "./ListingAvatar";
import { fetchListing } from "../../../actions/listings";

export default function Main(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const listing = props.data;

  function handleClick() {
    dispatch(fetchListing(props.data));
    history.push("/listings/listing");
  }

  return (
    <Card elevation={0}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2}>
              <ListingAvatar
                uri={listing.images && listing.images.split(",")[0]}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                style={{ fontFamily: "InterBold" }}
                variant="body1"
                noWrap
              >
                {listing.title}
              </Typography>

              <Typography variant="body2" noWrap color="text.secondary">
                {listing.address}
              </Typography>

              <Typography variant="body2">
                {getSymbolFromCurrency("NGN")}
                {listing.type == "sale"
                  ? listing.price
                  : listing.rent + "/" + listing.rent_period}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
