import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";
import Chip from "@mui/material/Chip";

import { updateProfile } from "../../actions/user";
import { getReviews } from "../../actions/reviews";
import PieChart from "../../components/web/reviews/Chart";

import { averageRating, ratingDistribution } from "../../functions/chart";

export default function Main() {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("grid");
  const [create, setCreate] = useState(false);
  const user = useSelector((state) => state.user.user);
  const reviews = useSelector((state) => state.review.reviews);
  const [data, setData] = useState(reviews ? reviews : []);
  const [form, setForm] = useState(user);
  const pieData = ratingDistribution(reviews);
  const [filter, setFilter] = useState("all");

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  function handleClick() {
    dispatch(updateProfile(form));
  }

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getReviews(body));
  }, []);

  function handleFilter(value) {
    setFilter(value);
    if (value != "all") {
      const filtered = reviews.filter((item) => item.rating == value);
      setData(filtered ? filtered : []);
    } else {
      setData(reviews ? reviews : []);
    }
  }

  return (
    <div>
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={11}>
          <Grid container direction="row">
            <Grid item md={6}>
              <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
                Reviews
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Manage your reviews
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginTop: 40 }} />
          {data.length > 0 && (
            <>
              <div style={{ justifyContent: "center" }}>
                <Typography
                  align="center"
                  variant="h6"
                  color="textSecondary"
                  style={{ fontFamily: "InterBold" }}
                >
                  OVERALL RATING
                </Typography>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ fontFamily: "SpaceBold" }}
                >
                  {averageRating(pieData)}
                </Typography>
              </div>

              <div style={{ height: 300, width: "100%" }}>
                <PieChart data={pieData} />
              </div>
            </>
          )}

          <div style={{ marginTop: 20 }} />
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Chip
                label="All"
                variant={filter == "all" ? "contained" : "outlined"}
                onClick={() => handleFilter("all")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Excellent"
                variant={filter == "excellent" ? "contained" : "outlined"}
                onClick={() => handleFilter("excellent")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Good"
                variant={filter == "good" ? "contained" : "outlined"}
                onClick={() => handleFilter("good")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Average"
                variant={filter == "average" ? "contained" : "outlined"}
                onClick={() => handleFilter("average")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Fair"
                variant={filter == "fair" ? "contained" : "outlined"}
                onClick={() => handleFilter("fair")}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Poor"
                variant={filter == "poor" ? "contained" : "outlined"}
                onClick={() => handleFilter("poor")}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }} />
          {data.length > 0 ? (
            data.map((item) => <ReviewItem data={item} />)
          ) : (
            <Typography color="textSecondary" align="center" variant="body1">
              {"  No reviews yet "}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function ReviewItem(props) {
  function formatDate(dateStr) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const dateObject = new Date(dateStr);

    // Get the month name and day number
    const monthName = months[dateObject.getMonth()];
    const dayNumber = dateObject.getUTCDate();

    return monthName + " " + dayNumber;
  }

  function getRating(rating) {
    switch (rating) {
      case "excellent":
        return 5;
      case "good":
        return 4;
      case "average":
        return 3;
      case "fair":
        return 2;
      case "poor":
        return 1;
    }
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          {" "}
          <Typography
            color="textSecondary"
            fontFamily={"SpaceBold"}
            variant="body2"
            align="justify"
          >
            {formatDate(props.data.created)}
          </Typography>
        </Grid>
        <Grid item>
          <Rating
            name="read-only"
            value={getRating(props.data.rating)}
            readOnly
          />
        </Grid>
      </Grid>
      <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
        {" "}
        Jane Doe{" "}
      </Typography>
      <Typography variant="body2" align="justify">
        {props.data.review}
      </Typography>

      <div style={{ marginTop: 5 }} />
      <Divider />
    </div>
  );
}
