import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import {
  ResponsiveContainer,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import {
  dailyValue,
  monthlyValue,
  yearlyValue,
} from "../../../functions/listings";

export default function Main() {
  const listings = useSelector((state) => state.listing.listings);
  const dailyData = dailyValue(listings);
  const monthlyData = monthlyValue(listings);
  const yearlyData = yearlyValue(listings);

  const [period, setPeriod] = useState("daily");

  function handlePeriodChange(value) {
    setPeriod(value);
  }

  return (
    <div style={{ height: 300, width: "100%" }}>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ fontFamily: "InterBold" }}
      >
        {" "}
        PROPERTY VALUE{" "}
      </Typography>
      <div style={{ marginTop: 10 }} />
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Chip
            clickable
            color={period == "daily" ? "success" : "default"}
            onClick={() => handlePeriodChange("daily")}
            variant={period == "daily" ? "filled" : "outlined"}
            label={<Typography variant="body2">Daily </Typography>}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            color={period == "monthly" ? "success" : "default"}
            onClick={() => handlePeriodChange("monthly")}
            variant={period == "monthly" ? "filled" : "outlined"}
            label={<Typography variant="body2">Monthly </Typography>}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            color={period == "yearly" ? "success" : "default"}
            onClick={() => handlePeriodChange("yearly")}
            variant={period == "yearly" ? "filled" : "outlined"}
            label={<Typography variant="body2">Yearly </Typography>}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 10 }} />

      <ResponsiveContainer width={"100%"} height="80%">
        <AreaChart
          data={
            period == "daily"
              ? dailyData
              : period == "monthly"
              ? monthlyData
              : yearlyData
          }
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="day" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="sale"
            stroke="#00C49F"
            fill="#00C49F"
          />
          <Area
            type="monotone"
            dataKey="rental"
            stroke="#0088FE"
            fill="#0088FE"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
