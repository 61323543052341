import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinkIcon from "@mui/icons-material/Link";
import ListSubheader from "@mui/material/ListSubheader";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AutoGraphIcon from "@mui/icons-material/AutoGraphRounded";
import PaymentIcon from "@mui/icons-material/PaymentRounded";
import ReviewsIcon from "@mui/icons-material/ReviewsRounded";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveRounded";

import UserAvatar from "../profile/UploadAvatar";

const urls = [
  {
    title: "Summary",
    url: "/dashboard",
    disabled: false,
    icon: <AutoGraphIcon color="primary" />,
  },
  {
    title: "Listings",
    url: "/listings",
    disabled: false,
    icon: <HomeIcon color="primary" />,
  },
  {
    title: "Links",
    url: "/links",
    disabled: false,
    visible: true,
    icon: <LinkIcon color="primary" />,
  },
  {
    title: "Requests",
    url: "/requests",
    disabled: false,
    icon: <FolderIcon color="primary" />,
  },
  {
    title: "Reviews",
    url: "/reviews",
    disabled: false,
    icon: <ReviewsIcon color="primary" />,
  },
  {
    title: "Billings",
    url: "/billings",
    disabled: true,
    icon: <PaymentIcon color="primary" />,
  },
  {
    title: "Notifications",
    url: "/notifications",
    disabled: true,
    icon: <NotificationsIcon color="primary" />,
  },
];

export default function Main() {
  const [drawer, setDrawer] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menu = Boolean(anchorEl);
  const user = useSelector((state) => state.user.user);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  function handleChange() {}

  function handleClick(item) {
    history.push(item.url);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }
  function handleLogout() {
    history.push("/");
  }
  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }
  return (
    <>
      <SwipeableDrawer
        anchor={"left"}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div
          role="presentation"
          style={{ width: "250px" }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div style={{ marginTop: "10%", padding: 20 }}>
            <img
              style={{ width: 100 }}
              src={require("../../../images/logo/1x/Asset 1.png")}
            />
          </div>

          <List>
            {urls.map((item, index) => (
              <ListItem
                disabled={item.disabled}
                key={item.title}
                disablePadding
              >
                <ListItemButton onClick={() => handleClick(item)}>
                  <ListItemIcon color="primary">{item.icon}</ListItemIcon>
                  <Typography
                    variant="body1"
                    color="primary"
                    fontFamily={"SpaceBold"}
                  >
                    {item.title}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </div>
      </SwipeableDrawer>
      <AppBar
        position="fixed"
        elevation={0}
        color="inherit"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <IconButton
            onClick={toggleDrawer(true)}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <UserAvatar uri={user.avatar} />

          <IconButton onClick={handleMenuClick} color="inherit">
            <MoreIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => history.push("/profile")}>
              <Typography variant="body2" fontFamily="SpaceBold">
                Profile
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography variant="body2" fontFamily="SpaceBold">
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}
