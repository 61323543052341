import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import getSymbolFromCurrency from "currency-symbol-map";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import ClearIcon from "@mui/icons-material/Clear";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import LivingIcon from "@mui/icons-material/Living";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import GarageIcon from "@mui/icons-material/Garage";

import ListingMedia from "../image/listing/ListingMedia";
import RealtorAvatar from "../image/realtor/RealtorAvatar";

import { uri } from "../../../constants/settings";
import ListingImage from "../image/gallery/ListingImage";
import { wait, notify, unexpectedError } from "../../../actions/index";
import { updateListing } from "../../../actions/listings";
import * as validator from "../../../validators/listings";

const base64 = require("base-64");

const steps = ["About", "Features", "Images", "Preview"];

const styles = {
  input: {
    display: "none",
  },
};

export default function Main(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const user = useSelector((state) => state.user.user);
  const listing = useSelector((state) => state.listing.listing);

  const [form, setForm] = useState(listing);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  const handleNext = () => {
    if (activeStep < 3) {
      const [status, message] = validator.addListing(form, activeStep);
      if (status == "success") {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        dispatch(notify({ status: status, message: message }));
      }
    } else if (activeStep == steps.length - 1) {
      const body = { ...form, published: "true" };
      dispatch(updateListing(body, props));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function handleImageChange(filename) {
    if (form.images == "" || form.images == null) {
      setForm((form) => ({ ...form, images: filename }));
    } else {
      let newFileName = form.images + "," + filename;
      setForm((form) => ({ ...form, images: newFileName }));
    }
  }

  function handleRemoveImage(value) {
    let images = form.images.split(",");
    images.pop(value);
    let new_images = images.join(",");
    setForm((form) => ({ ...form, images: new_images }));
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: 50 }} />
      <Grid container direction="row">
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} style={{ width: "100%" }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    <Typography variant="body1">{label}</Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <>
              <div style={{ marginTop: 50 }} />
              {activeStep == 0 && (
                <AboutForm form={form} handleChange={handleChange} />
              )}
              {activeStep == 1 && (
                <FeatureForm form={form} handleChange={handleChange} />
              )}
              {activeStep == 2 && (
                <ImageForm
                  form={form}
                  handleImageChange={handleImageChange}
                  removeImage={(value) => handleRemoveImage(value)}
                />
              )}

              {activeStep == 3 && (
                <Preview form={form} handleImageChange={handleImageChange} />
              )}

              <div style={{ marginTop: 50 }} />
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                <Typography variant="body1" fontFamily={"SpaceBold"}>
                  Back
                </Typography>
              </Button>

              <Button onClick={handleNext}>
                <Typography variant="body1" fontFamily="SpaceBold">
                  {" "}
                  {activeStep === steps.length - 1 ? "Update Listing" : "Next"}
                </Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function AboutForm(props) {
  const [form, setForm] = useState(props.form);

  function handleChange(event) {
    props.handleChange(event);
  }

  useEffect(() => {
    console.log(props.form);
  }, [props.form]);
  return (
    <>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          <Typography variant="body2" style={{ fontSize: 10 }}>
            Property Type
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="type"
          defaultValue={"sale"}
          onChange={handleChange}
        >
          <FormControlLabel value="sale" control={<Radio />} label="Sale" />
          <FormControlLabel value="rental" control={<Radio />} label="Rental" />
        </RadioGroup>
      </FormControl>
      <div style={{ marginTop: 10 }}>
        <TextField
          name="title"
          required
          variant="filled"
          defaultValue={form.title}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Title"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="address"
          required
          variant="filled"
          defaultValue={form.address}
          onChange={handleChange}
          fullWidth
          size="small"
          label="Address"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="description"
          required
          variant="filled"
          defaultValue={form.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          size="small"
          label="Description"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="State"
              name="state"
              required
              select
              variant="filled"
              onChange={handleChange}
              defaultValue={form.state}
              size="small"
              fullWidth
            >
              <MenuItem value="lagos"> Lagos </MenuItem>
              <MenuItem value="abuja"> Abuja </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Region/Location"
              name="location"
              required
              variant="filled"
              onChange={handleChange}
              defaultValue={form.location}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          {props.form.type != "rental" && (
            <Grid item xs={4}>
              <TextField
                label="Price"
                required
                type="number"
                name="price"
                variant="filled"
                onChange={handleChange}
                defaultValue={form.price}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {props.form.type == "rental" && (
            <>
              <Grid item xs={4}>
                <TextField
                  label="Rent"
                  required
                  type="number"
                  name="rent"
                  variant="filled"
                  onChange={handleChange}
                  defaultValue={form.rent}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Period"
                  name="rent_period"
                  required
                  select
                  variant="filled"
                  onChange={handleChange}
                  defaultValue={form.rent_period}
                  size="small"
                  fullWidth
                >
                  <MenuItem value="per-month"> Per Month </MenuItem>
                  <MenuItem value="per-annum"> Per Annum </MenuItem>
                </TextField>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
}

function FeatureForm(props) {
  const [form, setForm] = useState(props.form);

  function handleChange(event) {
    props.handleChange(event);
  }
  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Living Rooms"
              name="livingrooms"
              size="small"
              type="number"
              required
              variant="filled"
              onChange={handleChange}
              defaultValue={form.livingrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bedrooms"
              required
              type="number"
              name="bedrooms"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bedrooms}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Bathrooms"
              name="bathrooms"
              required
              type="number"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bathrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Kitchens"
              required
              name="kitchens"
              type="number"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.kitchens}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Garages"
              name="garages"
              type="number"
              required
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.garages}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total Area (sqft)"
              name="total_area"
              type="number"
              required
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.total_area}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function ImageForm(props) {
  const [form, setForm] = useState(props.form);
  const loading = useSelector((state) => state.app.loading);
  const dispatch = useDispatch();
  const user = "";
  const pass = "";
  const host = uri.mode === "dev" ? uri.dev : uri.live;
  const credentials = user + ":" + pass;

  useEffect(() => {
    setForm(props.form);
  }, [props.form]);

  async function onChange(e) {
    e.preventDefault();
    dispatch(wait(true));
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("photo", e.target.files[0]);

      var url = "";

      if (uri.mode == "test") {
        url = uri.test + "/gallery/listing/upload/";
      } else if (uri.mode == "live") {
        url = uri.live + "/gallery/listing/upload/";
      } else {
        url = uri.dev + "/gallery/listing/upload/";
      }

      setForm({
        photo: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      });
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Basic " + base64.encode(credentials),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          dispatch(notify({ message: "Image uploaded", status: "success" }));
          props.handleImageChange(res.response.image);
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            notify({ message: "Unexpected error occured", status: "error" })
          );
        });
    }
    dispatch(wait(false));
  }

  function handleChange(event) {
    props.handleChange(event);
  }
  return (
    <>
      <Alert icon={false} severity="info">
        <Typography variant="body2">
          Please upload at least two images
        </Typography>
      </Alert>

      <div style={{ marginTop: 10 }} />

      {loading == true && <LinearProgress color="primary" />}

      <Grid container direction="row" justifyContent="flex-end">
        {loading === false && (
          <Grid item>
            <IconButton component="label">
              <input
                onChange={(event) => onChange(event)}
                accept="image/*"
                id={props.name}
                style={styles.input}
                multiple
                type="file"
              />
              <AddPhotoAlternateIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <ImageList sx={{ width: "100%", height: 450 }} cols={1} rowHeight={200}>
        {form.images ? (
          form.images.split(",").map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${host + "/gallery/listing/image/" + item}`}
                src={`${host + "/gallery/listing/image/" + item}`}
                alt={item.title}
                style={{ height: 200 }}
                loading="lazy"
              />
              <ImageListItemBar
                title={item}
                subtitle={item}
                position="bottom"
                actionIcon={
                  <IconButton
                    onClick={() => props.removeImage(item)}
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  >
                    <ClearIcon color="inherit" />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))
        ) : (
          <Typography variant="body1" align="center"></Typography>
        )}
      </ImageList>
    </>
  );
}

function Preview(props) {
  const listing = props.form;
  const realtor = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.app.loading);
  const image = listing.images.split(",")[0];
  return (
    <div>
      {loading == true && <LinearProgress color="primary" />}
      <div style={{ marginTop: 20 }} />
      <Card
        elevation={0}
        sx={{
          maxWidth: 345,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 5,
          borderColor: "#E5E4E2",
        }}
      >
        <ListingMedia uri={image} />
        <CardContent>
          <Typography variant="h6" component="div" fontFamily="InterBold">
            {listing.title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {listing.address}
          </Typography>
          <Typography variant="h6" fontFamily="SpaceBold">
            {getSymbolFromCurrency("NGN")}
            {listing.type == "sale"
              ? listing.price
              : listing.rent + "/" + listing.rent_period}
          </Typography>

          <div style={{ marginTop: 20 }}>
            <Chip
              color="success"
              size="small"
              sx={{ borderRadius: 0 }}
              label={
                <Typography color="inherit" variant="body1">
                  Features
                </Typography>
              }
            />

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <BedIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.bedrooms} Bedrooms{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <LivingIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.livingrooms} Living Rooms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <ShowerIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.bathrooms} Bathrooms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <KitchenIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.kitchens} Kitchens
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <SquareFootIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.total_area} sqft.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item>
                    <GarageIcon />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {listing.garages} Garages
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={2}>
              <RealtorAvatar uri={realtor.avatar} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" fontFamily={"InterBold"}>
                {realtor.name}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {realtor.company_name}
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginTop: 10 }}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <LocalPhoneIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {realtor.phone}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1}>
              <Grid item>
                <MailOutlineIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  {realtor.email}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
