import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import { addLink } from "../../../actions/artifacts";

export default function Main(props) {
  const [open, setOpen] = useState(props.open);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [form, setForm] = useState({
    owner: user.id,
    title: "",
    description: "",
    url: "",
    type: "",
  });

  function handleClose() {
    props.close();
  }

  function handleSubmit() {
    dispatch(addLink(form, props));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar color="inherit" elevation={0} sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{ ml: 2, flex: 1 }}
            fontFamily={"InterBold"}
            variant="h6"
            component="div"
          >
            New Link
          </Typography>
          <Button
            autoFocus
            variant="outlined"
            color="inherit"
            onClick={handleSubmit}
          >
            <Typography variant="body1" fontFamily={"InterBold"}>
              Save
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent style={{ justifyContent: "center" }}>
        <TextField
          name="title"
          onChange={handleChange}
          fullWidth
          variant="standard"
          label="Title"
        />
        <div style={{ marginTop: 10 }} />
        <TextField
          name="description"
          variant="standard"
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          label="Description"
        />
        <div style={{ marginTop: 10 }} />
        <TextField
          name="url"
          onChange={handleChange}
          fullWidth
          variant="standard"
          label="URL"
        />
        <div style={{ marginTop: 10 }} />
        <FormControl>
          <FormLabel id="type-label">Resource Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="type-label"
            name="type"
            onChange={handleChange}
          >
            <FormControlLabel value="form" control={<Radio />} label="Form" />
            <FormControlLabel value="page" control={<Radio />} label="Page" />
            <FormControlLabel
              value="document"
              control={<Radio />}
              label="Document"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
}
