import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";

import { uri } from "../../../constants/settings";
import store from "../../../store/index";
import { wait, notify, unexpectedError } from "../../../actions/index";

import AddIcon from "@mui/icons-material/Add";

const base64 = require("base-64");

const styles = {
  input: {
    display: "none",
  },
};

export default function Main(props) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ photo: "", preview: "", upload: 0 });
  const host = uri.mode === "dev" ? uri.dev : uri.live;

  console.log(`${host} + "/gallery/listing/image/" + ${props.uri}`);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Avatar
        style={{ width: 60, height: 60 }}
        src={host + "/gallery/listing/image/" + props.uri}
      >
        <HomeIcon />
      </Avatar>
    </div>
  );
}
