import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import NewForm from "../../components/listing/NewForm";
import { createProperty } from "../../actions/property";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Main() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleSave() {}

  function handleClick() {}

  function handleSavePublish() {}

  return (
    <div>
      <div style={{ marginBottom: 0 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" style={{ fontFamily: "UberBold" }}>
              Listings
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  All Listings
                </Link>
                <Typography color="text.primary">Add Listing</Typography>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </div>

      <Grid container direction="row">
        <Grid item md={8}>
          <NewForm />
        </Grid>
      </Grid>
    </div>
  );
}
