import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import {
  FETCH_REQUEST,
  FETCH_REQUESTS,
  FETCH_LISTINGS,
} from "../constants/requests";

import * as validator from "../validators/listing";

export function getRequests(body) {
  const url = "/realtor/requests/all";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchRequests(res.response.requests));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getListings(body) {
  const url = "/realtor/requests/listings";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function updateListing(body) {
  const url = "/admin/listings/update";
  return async (dispatch) => {
    dispatch(wait(true));
    var [status, message] = validator.create(body);
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if (status == "ok") {
        if (res.response != null) {
          dispatch(fetchRequests(res.response.requests));
          dispatch(
            notify({
              message: "Listing updated successfully",
              status: "success",
            })
          );
        } else {
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: message, status: "error", show: true }));
    }
    dispatch(wait(false));
  };
}

export function deleteListing(body, history) {
  const url = "/admin/request/delete";
  return async (dispatch) => {
    dispatch(wait(true));
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      console.log(res);
      if (status == "ok") {
        if (res.response != null) {
          dispatch(fetchRequests(res.response.requests));
          dispatch(
            notify({
              message: "Listing updated successfully",
              status: "success",
            })
          );
        } else {
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: "", status: "error", show: true }));
    }
    dispatch(wait(false));
  };
}

export function fetchRequest(payload) {
  return { type: FETCH_REQUEST, payload };
}

export function fetchRequests(payload) {
  return { type: FETCH_REQUESTS, payload };
}

export function fetchListings(payload) {
  return { type: FETCH_LISTINGS, payload };
}
