import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import getSymbolFromCurrency from "currency-symbol-map";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import * as summary from "../../../functions/listings";

export default function Main() {
  const listings = useSelector((state) => state.listing.listings);
  const reviews = useSelector((state) => state.review.reviews);
  function handleClick() {}
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          direction="row"
          spacing={1}
        >
          <Grid item align="center">
            <Typography
              fontFamily="InterBold"
              color="textSecondary"
              variant="body1"
              align="center"
            >
              Listings
            </Typography>
            <Typography variant="h6" fontFamily="SpaceBold" align="center">
              {listings ? listings.length : 0}
            </Typography>
            <div style={{ marginTop: 10 }} />
            <Chip
              color="success"
              style={{ width: 100 }}
              icon={<TrendingUpIcon />}
              label="0.00%"
              onClick={handleClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          direction="row"
          spacing={1}
        >
          <Grid item align="center">
            <Typography
              fontFamily="InterBold"
              color="textSecondary"
              variant="body1"
              align="center"
            >
              TPV
            </Typography>
            <Typography variant="h6" fontFamily="SpaceBold" align="center">
              {getSymbolFromCurrency("NGN")} {summary.computeTPV(listings)}
            </Typography>
            <div style={{ marginTop: 10 }} />
            <Chip
              style={{ width: 100 }}
              color="success"
              icon={<TrendingUpIcon />}
              label="0.00%"
              onClick={handleClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          direction="row"
          spacing={1}
        >
          <Grid item align="center">
            <Typography
              fontFamily="InterBold"
              color="textSecondary"
              variant="body1"
              align="center"
            >
              Reviews
            </Typography>
            <Typography variant="h6" fontFamily="SpaceBold" align="center">
              {reviews ? reviews.length : 0}
            </Typography>
            <div style={{ marginTop: 10 }} />
            <Chip
              color="success"
              style={{ width: 100 }}
              icon={<TrendingUpIcon />}
              label="0.00%"
              onClick={handleClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
