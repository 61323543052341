export function addListing(form, step) {
  if (step == 0) {
    return about(form);
  } else if (step == 1) {
    return features(form);
  } else if (step == 2) {
    return images(form);
  }
}

function about(form) {
  let status = "error";
  let message = "";

  if (form.type == "" || form.type == null) {
    message = "Please specify the listing type";
  } else if (form.title == "" || form.title == null) {
    message = "Please provide the title of the listing";
  } else if (form.description == "" || form.description == null) {
    message = "Please provide a description of the listing";
  } else if (form.address == "" || form.address == null) {
    message = "Please provide the address of the listing";
  } else if (form.state == "" || form.state == null) {
    message = "Please specify the state where the listing is located";
  } else if (form.location == "" || form.location == null) {
    message = "Please specify the region";
  } else if (form.type == "sale" && (form.price == "" || form.price == null)) {
    message = "Please specify the price of the listing";
  } else if (form.type == "rental" && (form.rent == "" || form.rent == null)) {
    message = "Please specify the rent price";
  } else if (
    form.type == "rental" &&
    (form.rent_period == "" || form.rent_period == null)
  ) {
    message = "Please specify the rent period";
  } else {
    status = "success";
  }

  return [status, message];
}

function features(form) {
  let status = "error";
  let message = "";

  if (form.bedrooms == "" || form.bedrooms == null) {
    message = "Please specify the number of bedrooms";
  } else if (form.livingrooms == "" || form.livingrooms == null) {
    message = "Please specify the number of living rooms";
  } else if (form.bathrooms == "" || form.bathrooms == null) {
    message = "Please specify the number of bathrooms";
  } else if (form.kitchens == "" || form.kitchens == null) {
    message = "Please specify the number of kitchens";
  } else if (form.garages == "" || form.garages == null) {
    message = "Please specify the number of garages";
  } else if (form.total_area == "" || form.total_area == null) {
    message = "Please specify the total area";
  } else {
    status = "success";
  }

  return [status, message];
}

function images(form) {
  let status = "error";
  let message = "";
  if (form.images == null || form.images.split(",").length < 2) {
    message = "please upload at least two images";
  } else {
    status = "success";
  }
  return [status, message];
}

export function create(form) {
  var status = "error";
  var message = "";
  if (form.title == "" || form.title == null) {
    message = "Please provide the title of the listing";
  } else if (form.description == "" || form.description == null) {
    message = "Please provide a description of the listing";
  } else if (form.address == "" || form.address == null) {
    message = "Please provide a location of the listing";
  } else if (form.state == "" || form.state == null) {
    message = "Please specify the state where the listing is located";
  } else if (form.location == "" || form.location == null) {
    message = "Please specify the region";
  } else if (form.bedrooms == "" || form.bedrooms == null) {
    message = "Please specify the number of bedrooms";
  } else if (form.bathrooms == "" || form.bathrooms == null) {
    message = "Please specify the number of bathrooms";
  } else if (form.kitchens == "" || form.kitchens == null) {
    message = "Please specify the number of kitchens";
  } else if (form.total_area == "" || form.total_area == null) {
    message = "Please specify the total area";
  } else if (form.price == "" || form.price == null) {
    message = "Please specify the price of the listing";
  } else if (form.image1 == "" || form.image1 == null) {
    message = "Please upload at least two images";
  } else if (form.image2 == "" || form.image2 == null) {
    message = "Please upload at least two images";
  } else {
    status = "success";
  }

  return [status, message];
}
