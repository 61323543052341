import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import PublishIcon from "@mui/icons-material/Publish";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import AssetImage from "../image/onboarding/AssetImage";

import { createListing } from "../../../actions/listings";

export default function Main() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [form, setForm] = useState({
    realtor: user.id,
    portfolio: "default",
    state: "Lagos",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  function handleSave() {
    dispatch(createListing(form));
  }

  function handleSavePublish() {
    const body = { ...form, published: "true" };
    dispatch(createListing(body));
  }

  return (
    <div>
      <div>
        <TextField
          name="title"
          variant="filled"
          onChange={handleChange}
          fullWidth
          size="small"
          label="Title"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="address"
          variant="filled"
          onChange={handleChange}
          fullWidth
          size="small"
          label="Address"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          name="description"
          variant="filled"
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          size="small"
          label="Description"
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="State"
              name="state"
              select
              variant="filled"
              onChange={handleChange}
              defaultValue={form.state}
              size="small"
              fullWidth
            >
              <MenuItem value="lagos"> Lagos </MenuItem>
              <MenuItem value="abuja"> Abuja </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Region/Location"
              name="location"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.location}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Price"
              name="price"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.price}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Living Rooms"
              name="livingrooms"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.livingrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bedrooms"
              name="bedrooms"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bedrooms}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 10 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Bathrooms"
              name="bathrooms"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.bathrooms}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Kitchens"
              name="kitchens"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.kitchens}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Garages"
              name="garages"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.garages}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total Area (sqft)"
              name="total_area"
              size="small"
              variant="filled"
              onChange={handleChange}
              defaultValue={form.total_area}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 20 }}>
        <Typography variant="body2" color="textSecondary">
          Please upload at least two images
        </Typography>
        <div style={{ marginTop: 10 }} />
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <AssetImage
              uri={form.image1}
              onComplete={(value) =>
                setForm((form) => ({ ...form, image1: value }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AssetImage
              uri={form.image2}
              onComplete={(value) =>
                setForm((form) => ({ ...form, image2: value }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AssetImage
              uri={form.image3}
              onComplete={(value) =>
                setForm((form) => ({ ...form, image3: value }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AssetImage
              uri={form.image4}
              onComplete={(value) =>
                setForm((form) => ({ ...form, image4: value }))
              }
            />
          </Grid>
          <Grid item>
            <IconButton></IconButton>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginBottom: 50 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={4}>
            <Button
              fullWidth
              onClick={handleSave}
              size="large"
              startIcon={<SaveIcon />}
            >
              <Typography variant="body1">
                <b>Save</b>
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSavePublish}
              size="large"
              startIcon={<PublishIcon />}
            >
              <Typography variant="body1">
                <b>Save and Publish</b>
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
